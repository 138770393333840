@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&family=La+Belle+Aurore&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Fira Code', monospace;
}

.flex-container {
  display: flex;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
}

.main-container {
  background-color: var(--gray-400);
  width: calc(100vw - 17.5rem);
  margin-left: 17.5rem;
  overflow-x: hidden;
  overflow-y: hidden;
}

.main-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
  flex-wrap: wrap-reverse;
  padding: 10% 10%;
}

h1 {
  color: var(--white-100);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--gray-500);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hamburger-menu {
  display: none;
}

@media screen and (max-width: 1150px) {
  .main-container {
    width: 100vw;
    margin-left: auto;
  }

  .hamburger-menu {
    cursor: pointer;
    top: 41px;
    position: fixed;
    right: 10px;
    background-color: var(--gray-500);
    border: none;
    outline: none;
    height: 30px;
    display: block;
    z-index: 1000;
  }
  
  .line-1 , .line-2 , .line-3 {
    width: 30px;
    height: 2px;
    background-color: var(--white-100);
    margin: 7px;
    transition: all 0.3s;
  }

  .line-1.active {
    transform: rotate(-45deg) translate(-7px, 7px);
  }

  .line-2.active {
    opacity: 0;
  }

  .line-3.active {
    transform: rotate(45deg) translate(-5.5px, -6px);
  }
}